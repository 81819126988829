<template>
    <p class="alert alert-info mb-0">
        <i :class="iconClass"/>
        <span class="message">
            <slot/>
        </span>
    </p>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LelyEmptyPlaceholder extends Vue {
    @Prop({ default: 'help' })
    icon!: string;

    get iconClass(): string {
        return `lely-icon-${this.icon}`;
    }
}
</script>

<style lang="scss" scoped>
.alert {
    justify-content: center;
}

.message {
    text-align: center;
}
</style>
