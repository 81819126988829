import BreadcrumbInterface from './core/BreadcrumbInterface';
import { LelyTableActionsConfig, LelyTableColumnsConfig, LelyTableRowStatus } from './core/LelyTableTypes';
import LelyApp from './core/App';
import LelyUserDefinition from './core/UserDefinition';
import LelyAuth from './core/Auth';
import RelayService from './core/Relay/RelayService';
import Subscription from './core/Relay/Subscription';
import BoolResponse from './core/Relay/interfaces/responses/BoolResponse';
import StringResponse from './core/Relay/interfaces/responses/StringResponse';
import { VueConstructor } from 'vue/types/vue';
import * as components from './components';

export {
    BreadcrumbInterface,
    LelyTableActionsConfig,
    LelyTableColumnsConfig,
    LelyTableRowStatus,
    LelyApp,
    LelyUserDefinition,
    LelyAuth,
    RelayService,
    Subscription,
    BoolResponse,
    StringResponse
};

export * from './components'
export * from './core/Browserlogs';

const ComponentLibrary = {
    install(Vue: VueConstructor, options = {}) {
        // components
        for (const componentName in components) {
            // @ts-ignore
            Vue.component(componentName, components[componentName])
        }
    }
}

export default ComponentLibrary
