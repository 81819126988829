<template>
    <ul class="list-unstyled mb-0">
        <slot/>
    </ul>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class LelyPageHeaderDetailList extends Vue {

}
</script>

<style lang="scss" scoped>
ul {
    display: flex;
    gap: 12px;
}
</style>
