<template>
    <b-dropdown no-caret variant="square" v-if="visibleApps.length">
        <template #button-content>
            <i class="lely-icon-apps"/>
        </template>

        <b-dropdown-form class="apps">
            <a v-if="visibleApps.includes(lelyApp.ALARMCENTER)" :href="getHref(lelyApp.ALARMCENTER)" class="btn btn-secondary">
                <i class="lely-icon-alarm"/>
                Alarm center
            </a>
            <a v-if="visibleApps.includes(lelyApp.HMF)" :href="getHref(lelyApp.HMF)" class="btn btn-secondary">
                <i class="lely-icon-farm"/>
                How's my farm
            </a>
            <a v-if="visibleApps.includes(lelyApp.HMF_ACC)" :href="getHref(lelyApp.HMF_ACC)" class="btn btn-secondary">
                <i class="lely-icon-farm"/>
                How's my farm (ACC)
            </a>
            <a v-if="visibleApps.includes(lelyApp.HMS)" :href="getHref(lelyApp.HMS)" class="btn btn-secondary">
                <i class="lely-icon-cloud-done"/>
                How's my software
            </a>
            <a v-if="visibleApps.includes(lelyApp.KNIVESETS)" :href="getHref(lelyApp.KNIVESETS)" class="btn btn-secondary">
                <b-icon-scissors/>
                Messensetbeheer
            </a>
            <a v-if="visibleApps.includes(lelyApp.HMF_ADMIN)" :href="getHref(lelyApp.HMF_ADMIN)" class="btn btn-secondary">
                <i class="lely-icon-manage"/>
                HMF Admin
            </a>
        </b-dropdown-form>
    </b-dropdown>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { BIconScissors } from 'bootstrap-vue';
import LelyApp from '../core/App';
import LelyUserDefinition from '../core/UserDefinition';
import LelyAuth from '../core/Auth';

const urls = {
    [LelyApp.HMF]: 'https://hmf.next.lely.cloud',
    [LelyApp.HMF_ACC]: 'https://hmf-acc.next.lely.cloud',
    [LelyApp.HMS]: 'https://hms.next.lely.cloud',
    [LelyApp.KNIVESETS]: 'https://hmk.next.lely.cloud',
    [LelyApp.HMF_ADMIN]: 'https://hmf-admin.next.lely.cloud',
    [LelyApp.ALARMCENTER]: 'https://alarmcenter.next.lely.cloud',
}

@Component({
    components: {
        BIconScissors,
    }
})
export default class LelyAppSwitcher extends Vue {
    @Prop({})
    app!: LelyApp;

    user: LelyUserDefinition | null = null;
    lelyApp = LelyApp;

    mounted(): void {
        this.fetchUser();
    }

    async fetchUser(): Promise<void> {
        this.user = await LelyAuth.getCurrentUser();
    }

    get visibleApps(): LelyApp[] {
        if (!this.user) {
            return [];
        }

        let apps = [
            LelyApp.HMF,
        ];

        if (this.user.admin) {
            apps.push(LelyApp.HMF_ACC);
        }

        if (this.user.admin || this.user.developer) {
            apps.push(LelyApp.HMS, LelyApp.ALARMCENTER);
        }

        if (this.user.admin || this.user.kniveset) {
            apps.push(LelyApp.KNIVESETS);
        }

        if (this.user.admin) {
            apps.push(LelyApp.HMF_ADMIN);
        }

        if (this.app) {
            apps = apps.filter(app => app !== this.app);
        }

        return apps;
    }

    getHref(app: LelyApp): string {
        const token = LelyAuth.getAccessToken();
        return `${urls[app]}${token ? `/#/login?token=${token}` : ''}`;
    }
}
</script>

<style lang="scss" scoped>
.apps a {
    justify-content: flex-start;
    margin-bottom: 5px;
    width: 100%;
    white-space: nowrap;
}
</style>
