<template>
    <span>
        {{ rounded }}
    </span>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { TableColumn } from '../TableColumn';

@Component({
    components: {},
})
export default class LelyTableColumnNumber extends TableColumn {

    get rounded(): number {
        let num = Number(this.value);
        const p = Math.pow(10, Number(this.options?.precision) || 2);

        return Math.round(num * p) / p;
    }
}


</script>
