<template>
    <div>
        <div class="sidebar-fixed-wrapper" :class="{'sidebar-fixed-wrapper--no-margin': noMargin}">
            <slot name="sidebar"/>
        </div>
        <section class="main">
            <b-container :class="{'p-0': noMargin}">
                <b-row :no-gutters="noMargin">
                    <b-col>
                        <slot/>
                    </b-col>
                </b-row>
            </b-container>
        </section>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: { },
})
export default class LelySidebarLayout extends Vue {
    @Prop({
        type: Boolean,
        required: false,
        default: false,
    })
    noMargin!: boolean;
}
</script>

<style lang="scss" scoped>
.sidebar-fixed-wrapper {
    margin-right: 10px !important;

    &--no-margin {
        margin-right: 0 !important;
    }
}
</style>
