<template>
    <div>
        <div v-if="$slots.title || title" class="sidebar__group-header">
            <span>
                <slot name="title"><strong>{{ title }}</strong></slot>
            </span>

            <slot name="right"/>
        </div>
        <ul class="sidebar__navlist">
            <slot/>
        </ul>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LelyNavigationSidebarItemGroup extends Vue {
    @Prop()
    title!: string;
}

</script>

<style lang="scss" scoped>
.sidebar__group-header {
    color: white;
    display: flex;
    padding: 10px 20px 5px 20px;
    justify-content: space-between;
}

.sidebar__navlist {
    width: 170px;
}
</style>
