<template>
    <b-card border-variant="0"
            :title="title"
            class="lely-card"
            :class="{'flat': flat, 'no-padding': noPadding, 'expandable': expandable}"
    >
        <div v-if="$slots.title">
            <b-card-title>
                <slot name="title"/>
            </b-card-title>
        </div>
        <div v-if="$slots.right || $slots.teaser" class="lely-card-header" @click="expanded = !expanded">
            <div class="teaser">
                <i class="lely-icon-caret-right expanded-icon" :class="{'expanded-icon--opened': expanded}"/>
                <slot name="teaser"/>
            </div>

            <div class="right" v-if="$slots.right">
                <slot name="right"/>
            </div>
        </div>

        <div v-if="expandable" class="expandable-content" :class="{'expandable-content-opened': expanded}">
            <slot/>
        </div>
        <div v-else class="card-content" :class="bodyClassComputed">
            <slot/>
        </div>

        <b-card-footer v-if="$slots.footer">
            <slot name="footer"/>
        </b-card-footer>
    </b-card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class Card extends Vue {
    @Prop()
    title!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    noPadding!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    flat!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    expandable!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    initiallyExpanded!: boolean;

    @Prop({type: String, default: ''})
    bodyClass!: string;

    expanded = false;

    mounted(): void {
        this.expanded = this.initiallyExpanded;
    }

    get bodyClassComputed(): string {
        if (this.expandable && this.expanded) {
            return this.noPadding ? `p-0 expandable-content-opened ${this.bodyClass}` : `expandable-content-opened ${this.bodyClass}`;
        }

        return this.noPadding ? `p-0 ${this.bodyClass}` : this.bodyClass;
    }
}
</script>

<style lang="scss" scoped>
.lely-card {
    overflow: visible;
    min-height: 50px;
}

.card-title {
    margin: 20px 20px 0 20px;
}

.card-body {
    display: flex;
    flex-direction: column;
    padding: 0;

    .card-content {
        flex-grow: 1;
        padding: 20px;
    }
}

.expandable {
    .card-body {
        overflow: hidden;
    }

    .expandable-content {
        flex-grow: 1;
        overflow: hidden;
        max-height: 0;
        transition: all 0.15s ease-in;
        padding: 0 20px;
    }

    .expandable-content-opened {
        max-height: 600px;
        overflow: auto;
        padding: 10px 20px 20px 20px;
    }
}

.flat {
    box-shadow: none;
}

.lely-card-header {
    cursor: pointer;
    display: flex;
    padding: 20px;
}

.teaser {
    flex-grow: 1;
    display: flex;
}

.expanded-icon {
    display: inline-block;
    transition: transform .2s ease-in-out;
    height: 22px;

    &--opened {
        transform: rotate(90deg) !important;
    }
}

</style>
