<template>
    <div class="main-layout">
        <template v-if="$slots.header">
            <b-container fluid>
                <b-row>
                    <b-col>
                        <slot name="header"/>
                    </b-col>
                </b-row>
            </b-container>
        </template>
        <section class="main">
            <slot name="content">
                <b-container fluid>
                    <b-row>
                        <b-col>
                            <template v-if="flat">
                                <slot />
                            </template>
                            <Main v-else>
                                <slot/>
                            </Main>
                        </b-col>
                    </b-row>
                </b-container>
            </slot>
        </section>
        <div class="py-5 d-block d-sm-none"/> <!-- extra padding below footer on mobile -->
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import Main from './Main.vue';

@Component({
    components: {
        Main,
    }
})
export default class LelyMainLayout extends Vue {
    @Prop({ type: Boolean })
    flat!: boolean;
}
</script>

<style lang="scss" scoped>
.title h1 {
    margin: 0 0 25px 10px;
    font-size: 32px;
    font-weight: bold;
}

.main {
    min-height: calc(100vh - 180px);
}

.main-layout {
    margin-top: 20px;
    margin-bottom: 20px;
}

</style>
