<template>
    <td :class="{'text-right': right, 'text-center': center}">
        <slot/>
        <span v-if="$slots.sub" class="sub">
            <slot name="sub"/>
        </span>
    </td>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class LelyTableCell extends Vue {
    @Prop({})
    public sub!: string;

    @Prop({ type: Boolean })
    public right!: boolean;

    @Prop({ type: Boolean })
    public center!: boolean;
}

</script>

<style lang="scss" scoped>
@import "./../../assets/lely-red-rules/lely-styling/base/_variables.scss";

.sub {
    color: $color-dairy-darker;
    display: block;
    font-size: 12px;
    margin-top: 4px;
}
</style>

<style>
.table .unit {
    font-size: .8em;
}
</style>
