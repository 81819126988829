<template>
    <div class="pagination">
        <p>{{ value }} of {{ numberOfPages }}</p>
        <ul class="pagination__arrows">
            <li class="pagination__arrow prev">
                <a v-if="hasPrev" href="#" @click.prevent="prev()"></a>
            </li>
            <li class="pagination__arrow next">
                <a v-if="hasNext" href="#" @click.prevent="next()"></a>
            </li>
        </ul>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LelyPagination extends Vue {
    @Prop({
        required: true,
        type: Number,
    })
    value!: number;

    @Prop({
        required: true,
        type: Number,
    })
    numberOfPages!: number;

    next(): void {
        if (!this.hasNext) {
            return;
        }

        this.$emit('input', this.value + 1);
    }

    prev(): void {
        if (!this.hasPrev) {
            return;
        }

        this.$emit('input', this.value - 1);
    }

    get hasNext(): boolean {
        return this.value < this.numberOfPages;
    }

    get hasPrev(): boolean {
        return this.value > 1;
    }
}
</script>
