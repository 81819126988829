import { Prop, Vue } from 'vue-property-decorator';

export class TableColumn extends Vue {
    @Prop({ required: true })
    public value!: any;

    @Prop({
        default: () => ({})
    })
    public options!: Record<string, any>;
}
