<template>
    <RouterLink :to="to"
                :exact="exact"
                class="nav-item nav-link"
                active-class="active"
                data-toggle="tab"
                role="tab">
        <slot/>
    </RouterLink>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';

@Component({})
export default class LelyTabNavigationItem extends Vue {
    @Prop({
        type: Location,
        required: true,
    })
    to!: Location;

    @Prop({ type: Boolean })
    exact!: boolean
}
</script>
