<template>
    <li class="sidebar__navitem" :class="{'sidebar__navitem--footer': footer}">
        <RouterLink v-if="to" :to="to" :exact="exact" active-class="active"
                    class="btn sidebar__navbtn"
                    :class="{'sidebar__navbtn--grouped': grouped, 'sidebar__navbtn--compact': compact}">
            <span class="sidebar__icon">
                <slot name="icon"/>
            </span>
            <span class="sidebar__navtitle"><slot/></span>
        </RouterLink>

        <a v-else class="btn sidebar__navbtn"
           href="#"
           @click.prevent="$emit('click')"
           :class="{'sidebar__navbtn--grouped': grouped}">
            <span class="sidebar__icon" v-if="$slots.icon">
                <slot name="icon"/>
            </span>
            <span class="sidebar__navtitle"><slot/></span>
        </a>

        <div v-if="$slots.children">
            <slot name="children"/>
        </div>
    </li>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Location } from 'vue-router';

@Component({})
export default class LelyNavigationSidebarItem extends Vue {
    @Prop()
    to!: Location;

    @Prop({
        type: Boolean,
        default: false,
    })
    exact!: boolean;

    @Prop({
        type: Boolean,
        default: false,
    })
    footer!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    grouped!: boolean;

    @Prop({
        default: false,
        type: Boolean,
    })
    compact!: boolean;
}
</script>

<style lang="scss">
@import "./../../../assets/lely-red-rules/lely-styling/base/_variables.scss";

.sidebar__navbtn {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.sidebar__icon {
    text-align: center;
    color: $color-dairy-darker;
    margin-right: 10px;

    i {
        position: relative;
        top: 0;
        width: 18px;
    }

    svg {
        position: relative;
        top: -2px;
        width: 18px;
        height: 18px;
        margin-right: 0 !important;
        fill: white;
    }
}

.sidebar__navbtn--compact {
    height: 50px !important;
    line-height: 50px !important;
}

.sidebar__navbtn--grouped {
    height: 40px !important;
    line-height: 40px !important;

    span {
        font-weight: normal;
    }
}

.sidebar__navitem--footer {
    .sidebar__navtitle {
        font-weight: normal !important;
    }
}

</style>
