<template>
    <li>
        {{ label }}:
        <strong>
            <slot />
        </strong>
    </li>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LelyPageHeaderDetailListItem extends Vue {
    @Prop()
    label!: string;
}
</script>

<style lang="scss" scoped>
li {
    i {
        margin-top: -5px;
    }
}
</style>
