<template>
    <section class="section">
        <header class="section-title" v-if="title">
            <h1>{{ title }}</h1>
        </header>
        <div class="section-inner" :class="{'flat': flat}">
            <slot/>
        </div>
    </section>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class LelySection extends Vue {
    @Prop()
    title!: string;

    @Prop({
        type: Boolean,
        default: false,
    })
    flat!: boolean;
}
</script>

<style lang="scss" scoped>
@import "./../assets/lely-red-rules/lely-styling/base/_variables.scss";

.section-title h1 {
    font-size: 16px;
    font-weight: bold;
    margin: 15px 0 10px 0;
    line-height: 1;

    .section:first-child & {
        margin-top: 0;
    }
}

.section-inner:not(.flat) {
    background: $color-dairy-lighter;
    border-radius: $border-radius;
    padding: 20px;
}
</style>
