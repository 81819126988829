<template>
    <i class="lely-icon-check" v-if="value"/>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { TableColumn } from '../TableColumn';

@Component({
    components: {},
})
export default class LelyTableColumnCheck extends TableColumn {

}


</script>
