import Vue from 'vue';

let data: {
    app: string,
    authToken: () => string,
    userId: () => number,
};

const log = async (message: string, extra?: Record<string, any>) => {
    const body = `[${new Date().toISOString()}] ${message} - ${extra ? JSON.stringify(extra) : ''}`;

    await fetch(`https://lely-alarmcenter-backend.azurewebsites.net/api/web/browserlog/${data.app}/${data.userId() || 0}/${new Date().toISOString()}`, {
        method: 'POST',
        body,
        headers: {
            Authorization: `Bearer ${data.authToken()}`,
        },
    });
};

export const init = (app: string, userId: () => number, authToken: () => string): void => {
    if (process.env.NODE_ENV === 'development') {
        console.log('development, not listening for browserlogs...');

        return;
    }

    data = {
        app,
        userId,
        authToken,
    };

    window.addEventListener('error', (event) => log(event.message, {filename: event.filename, lineno: event.lineno}));

    Vue.config.errorHandler = (err, vm, info) => {
        const component = vm.$options?.name;
        log(`${err.name} - ${err.message}`, {info, component}).then();

        console.error(err);
    };

};

export default init;
