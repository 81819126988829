<template>
    <tr :class="statusClass">
        <td v-if="showExpanded" class="expanded-cell" :class="{'is-expanded': expanded}">
            <b-btn variant="icon" @click="$emit('toggleExpanded')">
                <i class="lely-icon-caret-right"/>
            </b-btn>
        </td>
        <td v-if="showCheckbox" class="checkbox-cell">
            <b-checkbox v-model="isChecked"/>
        </td>
        <slot/>
    </tr>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue } from 'vue-property-decorator';
import { LelyTableRowStatus } from '../../core/LelyTableTypes';

@Component({})
export default class LelyTableRow extends Vue {
    @Model()
    readonly value?: boolean;

    @Prop()
    readonly expanded?: boolean;

    @Prop()
    readonly status?: LelyTableRowStatus;

    get showCheckbox(): boolean {
        return this.value !== undefined;
    }

    get showExpanded(): boolean {
        return this.expanded !== undefined;
    }

    get isChecked(): boolean {
        return this.value === true;
    }

    set isChecked(val: boolean) {
        if (val !== this.value) {
            this.$emit('input', val);
        }
    }

    get statusClass(): Record<string, boolean> | undefined {
        if (!this.status) {
            return undefined;
        }

        return {'row-status': true, [`row-status--${this.status}`]: true}
    }
}

</script>

<style lang="scss" scoped>
@import "./../../assets/lely-red-rules/lely-styling/base/_variables.scss";

.row-status--critical td:first-child {
    border-left: 5px solid $brand-danger !important;
}

.row-status--warning td:first-child {
    border-left: 5px solid $brand-warning !important;
}

.row-status--advice td:first-child {
    border-left: 5px solid $brand-info !important;
}

.expanded-cell i {
    display: inline-block;
    margin-top: -6px;
    transition: transform .1s ease-in-out;
}

.is-expanded i {
    transform: rotate(90deg) !important;
    margin-top: 3px;
}

</style>
