<template>
    <th :class="{'text-right': right, 'text-center': center}" :style="{width: shrink ? '1px': grow ? '100%' : undefined}">
        <slot/>
        <span v-if="$slots.sub" class="sub">
           <slot name="sub"/>
        </span>
    </th>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class LelyTableHeader extends Vue {
    @Prop({ type: Boolean })
    public shrink!: boolean;

    @Prop({ type: Boolean })
    public grow!: boolean;

    @Prop({ type: Boolean })
    public right!: boolean;

    @Prop({ type: Boolean })
    public center!: boolean;
}

</script>

<style lang="scss" scoped>
th {
    vertical-align: middle;
    white-space: nowrap !important;
}

.sub {
    display: block;
    font-size: 12px;
}
</style>
