import LelyUserDefinition from './UserDefinition';
import axios from 'axios';

export interface LelyAuthConfig {
    ssoUrl?: string;
    currentUserUrl?: string;
    accessTokenLocalStorage?: string;
    credentialsUrl?: string;
}

export default class LelyAuth {
    private static accessTokenLocalStorage = 'lely_access_token';
    private static currentUserUrl = 'https://lely-hmf-backend.azurewebsites.net/api/web/users/me';
    private static ssoUrl = 'https://hmf-backend.next.lely.cloud/api/login/sso';
    private static credentialsUrl = 'https://lely-hmf-backend.azurewebsites.net/api/login';

    public static setConfig(config?: LelyAuthConfig): void {
        if (config?.accessTokenLocalStorage) {
            LelyAuth.accessTokenLocalStorage = config?.accessTokenLocalStorage;
        }

        if (config?.ssoUrl) {
            LelyAuth.ssoUrl = config?.ssoUrl;
        }

        if (config?.currentUserUrl) {
            LelyAuth.currentUserUrl = config?.currentUserUrl;
        }

        if (config?.credentialsUrl) {
            LelyAuth.credentialsUrl = config?.credentialsUrl;
        }
    }

    public static initSso(): void {
        window.location.href = `${LelyAuth.ssoUrl}?redirect_url=${encodeURIComponent(window.location.href)}`;
    }

    public static isAuthenticated(): boolean {
        return window.localStorage.getItem(LelyAuth.accessTokenLocalStorage) !== null;
    }

    public static async authenticateByCredentials(email: string, password: string): Promise<LelyUserDefinition> {
        if (!LelyAuth.credentialsUrl) {
            throw new Error('No credentialsUrl provided');
        }

        const response = await axios.post(LelyAuth.credentialsUrl, {
            email,
            password,
        });

        window.localStorage.setItem(LelyAuth.accessTokenLocalStorage, response.data.access_token);

        axios.defaults.headers.common.Authorization = `Bearer ${LelyAuth.getAccessToken()}`;

        return response.data;
    }

    public static async authenticateByToken(token: string): Promise<LelyUserDefinition> {
        LelyAuth.setAccessToken(token);

        return LelyAuth.getCurrentUser();
    }

    public static async getCurrentUser(): Promise<LelyUserDefinition> {
        return (await axios.get<LelyUserDefinition>(LelyAuth.currentUserUrl)).data;
    }

    private static setAccessToken(token: string): void {
        window.localStorage.setItem(LelyAuth.accessTokenLocalStorage, token);

        axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    }

    public static getAccessToken(): string | null {
        if (typeof window === 'undefined') {
            return null;
        }

        return window.localStorage.getItem(LelyAuth.accessTokenLocalStorage);
    }

    public static logout(): void {
        window.localStorage.removeItem(LelyAuth.accessTokenLocalStorage);
    }
}
