<template>
    <div class="sidebar d-none d-sm-block" data-testid="sidebar">
        <div class="sidebar__bar">
            <div class="sidebar__bar-inner">
                <slot/>
            </div>
            <div class="sidebar__bar-footer" v-if="$slots.footer">
                <hr class="mx-2 mb-0" />
                <slot name="footer"/>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class LelyNavigationSidebar extends Vue {

}

</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
    .sidebar__navlist {
        width: 170px;
    }

    .sidebar__bar-inner {
        overflow-y: auto;
    }

    .sidebar__navbtn {
        display: flex;
        flex-direction: row;
    }

    .sidebar__navtitle {
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 20px;
    }
}
</style>
