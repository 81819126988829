enum LelyApp {
    ALARMCENTER = 'ALARMCENTER',
    HMF = 'HMF',
    HMF_ACC = 'HMF_ACC',
    HMS = 'HMS',
    KNIVESETS = 'KNIVESETS',
    HMF_ADMIN = 'HMF_ADMIN',
}

export default LelyApp;
