<template>
    <div class="top-navigation-fixed-wrapper">
        <div class="top-navigation">
            <div class="top-navigation__inner">
                <div class="d-flex align-items-center">
                    <LelyAppSwitcher :app="app" class="mr-2"/>
                    <router-link :to="home" class="top-navigation__logo d-none d-md-block">
                        <span class="top-navigation__logotext">{{ title }}</span>
                    </router-link>
                    <div class="loading-container">
                        <b-spinner v-if="loading" class="ml-2" small/>
                    </div>
                </div>
                <slot/>
                <div class="d-flex justify-content-end align-items-center">
                    <slot name="right"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import LelyApp from '../../core/App';
import LelyAppSwitcher from '../AppSwitcher.vue';
import LelyUserDefinition from '../../core/UserDefinition';

@Component({
    components: {LelyAppSwitcher}
})
export default class LelyTopNavigation extends Vue {
    @Prop({required: true})
    home!: RawLocation;

    @Prop({required: true})
    title!: string;

    @Prop({type: Boolean})
    loading!: boolean;

    @Prop({default: null})
    app!: LelyApp;

    @Prop({default: null})
    user!: LelyUserDefinition;
}

</script>

<style lang="scss" scoped>
.top-navigation-fixed-wrapper {
    height: 60px;
    width: 100%;
}

.top-navigation {
    z-index: 500;
}

</style>
