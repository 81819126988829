<template>
    <div class="main__inner">
        <div class="main__inner-block">
            <slot/>
        </div>
    </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class LelyMainInner extends Vue {
}
</script>
