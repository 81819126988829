<template>
    <span :class="classnames">
        {{ value }}
    </span>
</template>

<script lang="ts">
import { Component } from 'vue-property-decorator';
import { TableColumn } from '../TableColumn';

@Component({
    components: {},
})
export default class LelyTableColumnText extends TableColumn {
    get classnames(): string[] {
        let classes = this.options.classnames || [];

        if (this.options.wrap) {
            classes.push('text-wrap');
        }

        return classes;
    }
}

</script>
