<template>
    <Card class="mb-2">
        <slot/>
    </Card>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import LelyCard from '../Card.vue';

@Component({
    components: {
        Card: LelyCard,
    }
})
export default class LelyMain extends Vue {

}
</script>
