<template>
    <b-nav tag="nav" role="tablist" :pills="pills" class="tab-navigation nav nav-links d-inline-flex">
        <slot/>
    </b-nav>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LelyTabNavigation extends Vue {
    @Prop({
        default: false,
        type: Boolean,
    })
    pills!: boolean;
}
</script>
