<template>
    <nav class="breadcrumbs-container" aria-label="breadcrumb">
        <ol class="breadcrumb">
            <li class="breadcrumb-item breadcrumb-item--icon mb-0">
                <RouterLink :to="root">
                    <i class="lely-icon-dashboard"/>
                </RouterLink>
            </li>
            <li v-for="(item, index) in breadcrumbs" :key="index" class="breadcrumb-item mb-0">
                <template v-if="index < breadcrumbs.length-1">
                    <template v-if="item.location">
                        <RouterLink :to="item.location">
                            {{ item.label }}
                        </RouterLink>
                    </template>
                    <template v-else>
                        {{ item.label }}
                    </template>
                </template>
                <template v-else>
                    <template v-if="item.options">
                        <b-dropdown>
                            <template #button-content>
                                <span class="button-content">
                                {{ item.label }}
                                </span>
                            </template>
                            <b-dropdown-item
                                v-for="(option, optionIndex) in item.options"
                                :key="optionIndex"
                                @click="$router.push(option.location)">
                                {{ option.label }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                    <template v-else>
                        {{ item.label }}
                    </template>
                </template>
            </li>
        </ol>
    </nav>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import BreadcrumbInterface from '../core/BreadcrumbInterface';

@Component({})
export default class LelyBreadcrumbs extends Vue {
    @Prop({ required: true })
    breadcrumbs!: BreadcrumbInterface[];

    get root(): RawLocation {
        return this.$route.matched[0];
    }
}
</script>

<style lang="scss" scoped>
@import "./../assets/lely-red-rules/lely-styling/base/_variables.scss";

.button-content {
    font-size: 22px;
    font-weight: 300;
}

</style>
