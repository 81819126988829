<template>
    <Card class="mb-4">
        <div class="d-flex justify-content-between mb-2">
            <Breadcrumbs :breadcrumbs="computedBreadcrumbs"/>
            <div class="d-flex flex-row">
                <slot name="actions"/>
            </div>
        </div>
        <div class="d-flex">
            <slot/>
        </div>
        <template v-if="$slots.tabs">
            <hr class="mt-0"/>
            <slot name="tabs"/>
        </template>
    </Card>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import LelyBreadcrumbs from '../Breadcrumbs.vue';
import LelyCard from '../Card.vue';
import BreadcrumbInterface from '../../core/BreadcrumbInterface';

@Component({
    components: { Breadcrumbs: LelyBreadcrumbs, Card: LelyCard },
})
export default class LelyPageHeader extends Vue {
    @Prop({})
    breadcrumbs!: BreadcrumbInterface[];

    @Prop({})
    title!: string;

    get computedBreadcrumbs(): BreadcrumbInterface[] {
        if (this.title) {
            return [
                {
                    label: this.title,
                },
            ];
        }

        return this.breadcrumbs;
    }
}
</script>
