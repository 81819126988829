<template>
    <component :is="componentType"
               :item="item"
               :value="value"
               :options="options"/>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import LelyTableColumnText from './Column/Text.vue';
import LelyTableColumnNumber from './Column/Number.vue';
import LelyTableColumnCheck from './Column/Check.vue';
import { LelyTableColumnType, LelyTableValueConfig } from '../../core/LelyTableTypes';
import VNodeRenderer from './VNodeRenderer';

@Component({
    components: {
        LelyTableColumnText,
        LelyTableColumnNumber,
        LelyTableColumnCheck,
        VNodeRenderer,
    },
})
export default class LelyTableColumnRenderer<T> extends Vue {
    @Prop({required: true})
    public item!: T;

    @Prop({})
    public type!: LelyTableColumnType;

    @Prop({required: true})
    public valueConfig!: LelyTableValueConfig<T>;

    @Prop({default: () => ({})})
    public options!: Record<string, any>;

    get value(): any {
        if (typeof this.valueConfig === 'function') {
            return this.valueConfig(this.item);
        }

        if (typeof this.valueConfig === 'string') {
            return this.item[this.valueConfig];
        }

        return this.valueConfig;
    }

    get isVNode(): boolean {
        return this.value?.tag;
    }

    get componentType(): string {
        if (this.isVNode) {
            return 'VNodeRenderer';
        }

        switch (this.type) {
            case 'text':
                return 'LelyTableColumnText';
            case 'number':
                return 'LelyTableColumnNumber';
            case 'check':
                return 'LelyTableColumnCheck';
            default:
                switch (typeof this.value) {
                    case 'boolean':
                        return 'LelyTableColumnCheck';
                    case 'number':
                        return 'LelyTableColumnNumber';
                    default:
                        return 'LelyTableColumnText';

                }
        }
    }
}

</script>

<style lang="scss" scoped>
@import "./../../assets/lely-red-rules/lely-styling/base/_variables.scss";


</style>
